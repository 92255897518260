/**
 * Company: SPHMedia
 * Description: [Translated Article Modal]
 */

import TranslateModalClose from "@assets/article/translateModalClose.svg";
import { StandFirst } from "@components/Elements/Typography/Paragraph";
import { FieldWrapper } from "@components/Translation/Elements/FieldWrapper";
import TranslationImage from "@components/Translation/Elements/TranslationImage";
import {
  TranslationAdsImu1,
  TranslationAdsMidArticle,
} from "@elements/Advertisement/variant/TranslationAds";
import Heading from "@elements/Typography/Heading";
import {
  AdTargetingType,
  ArticleAdTargetKeys,
  PageAdTargetingTypeEnum,
} from "@typings/Ads.d";
import Dompurify from "dompurify";
import React from "react";
import { ReactElement } from "react";

export type TranslatedArticleModalProps = {
  id: string;
  title: string;
  sectionName: string;
  standFirst?: string;
  body: string[];
  imageUrl?: string;
  isOpen: boolean;
  onClose: () => void;
  gsChannels?: string[];
  articleSlotTarget?: AdTargetingType<ArticleAdTargetKeys, string>[];
  pageNumber: number;
};

/**
 * TranslatedArticleModal
 * Description: [Opens translated content of article as a modal]
 * @param props - The props required by the component
 * @returns The rendered React element
 */
export default function TranslatedArticleModal(
  props: TranslatedArticleModalProps,
): ReactElement {
  const {
    id,
    title,
    sectionName,
    standFirst,
    body,
    imageUrl,
    onClose,
    gsChannels,
    articleSlotTarget,
    pageNumber,
  } = props;
  const pageAdTarget = PageAdTargetingTypeEnum.TRANSLATION;

  return (
    <div id={`trans-${id}`} className="flex flex-col items-center mb-md">
      <div className="flex justify-between items-center w-full h-[48px] lg:max-w-[860px] mx-auto bg-white-100 pl-[16px] pr-[8px] sticky top-0">
        <span className="text-blue-400 text-sm font-primary font-semimedium">
          Translation by Google Translate
        </span>
        <button className="" onClick={onClose}>
          <img
            src={TranslateModalClose}
            alt="translate-close"
            width={23}
            height={23}
          />
        </button>
      </div>
      <FieldWrapper>
        <div className="flex flex-col gap-xs">
          <TranslationImage sectionName={sectionName} alt={""} url={imageUrl} />
          <div className="flex flex-col gap-xs px-sm lg:px-0">
            <Heading
              Element="h2"
              className="font-secondary leading-2xl text-black-800 text-xl lg:text-[38px] font-medium"
            >
              {title}
            </Heading>
            {standFirst && (
              <StandFirst className="font-medium text-black-800 lg:text-[20px] my-3 lg:my-4">
                {standFirst}
              </StandFirst>
            )}
          </div>
        </div>
      </FieldWrapper>
      <FieldWrapper className="flex flex-col w-full gap-xs mt-[10px]">
        {body.map((para, index) => (
          <React.Fragment key={index}>
            {index === 2 && (
              <TranslationAdsImu1
                uniqueSectionName={sectionName}
                pageAdTargetType={pageAdTarget}
                index={pageNumber}
                gsChannels={gsChannels}
                articleSlotTarget={articleSlotTarget}
              />
            )}
            {index === 6 && (
              <TranslationAdsMidArticle
                uniqueSectionName={sectionName}
                pageAdTargetType={pageAdTarget}
                index={pageNumber}
                gsChannels={gsChannels}
                articleSlotTarget={articleSlotTarget}
              />
            )}
            <p
              dangerouslySetInnerHTML={{ __html: Dompurify.sanitize(para) }}
              className="text-sm font-primary font-semimedium text-black-800 leading-[24px] px-sm lg:px-0"
            />
          </React.Fragment>
        ))}
      </FieldWrapper>
    </div>
  );
}
