import { CompanionAdsLb } from "@elements/Advertisement/variant/CompanionAdsLb";
import ArticleCard, {
  ArticleCardStyleProps,
} from "@elements/Card/Article/ArticleCard";
import {
  CreateLoadMoreHandler,
  type CreateLoadMoreHandlerProps,
} from "@elements/LoadMore/CreateLoadMoreHandler";
import LoadMore from "@elements/LoadMore/LoadMore";
import useSetFirstRenderedArticleIds from "@elements/LoadMore/SetFirstRenderedArticleIds";
import fetchBHApi from "@helper/fetchBHApi";
import { displayAfter } from "@helper/utils";
import styles from "@pages/UGC/UGCLandingPage/UGCLandingPage.module.css";
import type { ProcessedArticleData } from "@transformer/useOSResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import type { MoreArticlesRequestData } from "@typings/MoreArticlesApi";
import React, { useState } from "react";

const PAGE_SIZE = 4;
const INITIAL_ARTICLE_COUNT = 4;

type UGCMonthlyCampaignListProps = {
  monthlyCampaigns: ProcessedArticleData[];
  overrideStyle: ArticleCardStyleProps;
  ugcSection: string;
};

export default function UGCMonthlyCampaignList({
  monthlyCampaigns,
  overrideStyle,
  ugcSection,
}: UGCMonthlyCampaignListProps) {
  const [hasMoreStories, setHasMoreStories] = useState<boolean>(true);
  const [renderedArticleIds, setRenderedArticleIds] = useState(
    new Set<string>(),
  );
  const [loadMoreData, setLoadMoreData] = useState<ProcessedArticleData[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const showLoadMore: boolean =
    monthlyCampaigns.length == INITIAL_ARTICLE_COUNT;

  /**
   * Sets a precaution for duplicate article ids when rendering additional articles
   */
  useSetFirstRenderedArticleIds(monthlyCampaigns, setRenderedArticleIds);

  const handleLoadMore = async () => {
    const lastLoadedArticle =
      loadMoreData.length === 0 ? monthlyCampaigns.at(-1) : loadMoreData.at(-1);

    const requestData: MoreArticlesRequestData = {
      sort: lastLoadedArticle?.sort,
    };

    const fetchUGCLandingPageMoreData = async (
      requestData: MoreArticlesRequestData,
    ): Promise<ProcessedArticleData[] | []> => {
      const queryParams = new URLSearchParams({
        sort: Array.isArray(requestData.sort) ? requestData.sort.join(",") : "",
      }).toString();
      const response: ProcessedArticleData[] = await fetchBHApi(
        `more-ugc-thematic-campaigns?${queryParams}`,
        "GET",
      );

      return response;
    };

    const createLoadMoreHandlerProps: CreateLoadMoreHandlerProps = {
      requestData,
      fetchFunction: fetchUGCLandingPageMoreData,
      setHasMoreStories,
      setRenderedArticleIds,
      setLoadMoreData,
      setPageNumber,
      lastLoadedArticle,
      loadMoreData,
      renderedArticleIds,
      pageSize: PAGE_SIZE,
      pageNumber,
    };

    const response = await CreateLoadMoreHandler(createLoadMoreHandlerProps);
    return response;
  };

  return (
    <>
      {[...monthlyCampaigns, ...loadMoreData].map((context, index) => {
        const dynamicClass = index % 2 === 0 ? styles.evenItem : styles.oddItem;

        return (
          <React.Fragment key={index}>
            {displayAfter(index, PAGE_SIZE) && (
              <div className={styles.ugcAdsMainContent}>
                <CompanionAdsLb
                  uniqueSectionName={ugcSection}
                  index={index / PAGE_SIZE}
                  stripeBackground={false}
                  pageAdTargetType={PageAdTargetingTypeEnum.LISTING}
                />
              </div>
            )}
            <div className={`${styles.campaignCards} ${dynamicClass}`}>
              <ArticleCard
                displayWidth={454}
                displayHeight={303}
                elements={{
                  hasTitle: true,
                  hasParagraph: true,
                  dateVariant: "dash",
                }}
                overrideStyle={overrideStyle}
                {...context}
              />
            </div>
          </React.Fragment>
        );
      })}
      {showLoadMore ? (
        <div className="flex w-full justify-center">
          <LoadMore
            rootClassName="my-4 w-full"
            onLoadMore={handleLoadMore}
            hasMore={hasMoreStories}
            loadText="Lagi Cerita"
          />
        </div>
      ) : null}
    </>
  );
}
