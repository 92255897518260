import MosqueLogo from "@assets/prayer-time/Mosque-new.svg";
import prayerDetails from "@assets/prayer-time/PrayerTimetable.json";
import styles from "@components/HeaderNew/PrayerTime/PrayerTime.module.css";
import cx from "classnames";

export type CSVRow = {
  Date: string;
  Day: string;
  Subuh: string;
  Syuruk: string;
  Zuhur: string;
  Asar: string;
  Maghrib: string;
  Isyak: string;
};

type PrayerTimeProps = {
  currentDate: string;
  isMobile?: boolean;
};

export function PrayerTime({ currentDate, isMobile }: PrayerTimeProps) {
  const prayerData =
    prayerDetails.find((prayer) => {
      return prayer.Date === currentDate;
    }) ?? null;
  return (
    <div
      className={cx(styles.prayerTimesContainer, {
        [styles.isMobile]: isMobile,
      })}
    >
      <>
        {prayerData ? (
          <div className={cx(styles.prayerTimesItems)}>
            <div className={cx(styles.prayerTimesLogo, "pr-xxs")}>
              <img src={MosqueLogo} alt="Mosque Logo" height={17} width={20} />
            </div>
            {Object.keys(prayerData).map((key) => {
              if (key !== "Date" && key !== "Day") {
                return (
                  <div key={key} className={cx(styles.prayerTimeItem)}>
                    <div className={cx(styles.prayerTimeLabel)}>{key}</div>
                    <div className={cx(styles.prayerTimeValue)}>
                      {prayerData[key as keyof CSVRow].replace(" ", ":")}
                    </div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        ) : (
          <p
            data-testId="prayer-times-not-available"
            className="prayer-times-not-available"
          >
            Prayer times for the current date are not available.
          </p>
        )}
      </>
    </div>
  );
}
