import { isClientSide } from "@helper/utils";
import { useEffect, useRef, useState } from "react";

export default function useVisibleArticle(triggerState: string) {
  const [visibleArticleId, setVisibleArticleId] = useState<
    string | undefined
  >();
  const observerRef = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    if (!isClientSide) return;

    const articles = document.querySelectorAll(".article-card"); // Ensuring you select the correct elements by class

    // Setup the Intersection Observer
    observerRef.current = new IntersectionObserver(
      (entries) => {
        let maxVisibleId = "";
        let maxVisibility = 0;

        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const visibility = entry.intersectionRatio * 100; // Convert to percentage
            // Track the article with the highest visibility (to prevent edge cases)
            if (visibility > maxVisibility) {
              maxVisibility = visibility;
              maxVisibleId = entry.target.id; // Use the correct ID of the article card
            }
          }
        });

        // Update the visible article ID if a new one is detected
        setVisibleArticleId(maxVisibleId);
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: [0.1, 0.25, 0.5, 0.75, 1], // Adjust thresholds to make sure we can detect visibility changes
      },
    );

    // Start observing each article
    articles.forEach((article) => observerRef.current?.observe(article));

    // Cleanup observer on component unmount
    return () => {
      articles.forEach((article) => observerRef.current?.unobserve(article));
      observerRef.current?.disconnect();
    };
  }, [triggerState]); // Empty dependency array ensures this runs once on mount

  return visibleArticleId;
}
