import { ReactNode } from "react";
import { twMerge } from "tailwind-merge";

import Heading from "./Heading";

export type ParagraphProps = {
  children: ReactNode;
  testid?: string;
  className?: string;
  isMobile?: boolean;
};

export default function Paragraph({
  testid = undefined,
  children,
  className,
  isMobile = false,
}: ParagraphProps) {
  const defaultClass = isMobile
    ? undefined
    : twMerge(
        "font-primaryLight text-black-200 text-2md mb-4 font-extrabold",
        className,
      );
  return (
    <p data-testid={testid} className={defaultClass}>
      {children}
    </p>
  );
}

export function StandFirst({ children, className }: ParagraphProps) {
  return (
    <Heading
      className={twMerge(
        "text-grey-400 font-secondary font-semibold leading-xl text-md lg:text-xl",
        className,
      )}
      Element="h6"
    >
      {children}
    </Heading>
  );
}
