import { getErrorMessage } from "@helper/getErrorMessage";
import { create } from "zustand";

export interface UserStore {
  userInfo: OKTAUserType;
  setUserInfo: (userInfo: OKTAUserType) => void;
  userError: unknown;
  setUserError: (error: ReturnType<typeof getErrorMessage>) => void;
}

export type OKTAUserType = {
  display_name?: string;
  loginid?: string;
  mySPHSessionId?: string;
  mysphw?: string;
  reguserstatus?: string;
  seamless_login_from_app?: string;
  service_type?: string;
  service_type_value?: 1 | 0 | null;
  usertype?: OKTAUserTypeEnum;
  svc?: string;
} | null;

export enum OKTAUserTypeEnum {
  SUBSCRIBER = "y-sub",
  REGISTERED = "y-reg",
  ANONYMOUS = "y-anoy",
  GUEST = "y-guest",
}

export enum OKTAUserSeviceFlagEnum {
  BH_ONLINE = "bh_online",
  BH_GENG = "bh_geng",
}

const useOKTAUserStore = create<UserStore>((set) => ({
  userInfo: {
    service_type: "",
    display_name: "",
    mysphw: "",
    reguserstatus: "",
    mySPHSessionId: "",
    usertype: OKTAUserTypeEnum.ANONYMOUS,
    loginid: "",
  },
  setUserInfo: (userInfo: OKTAUserType) => {
    set({ userInfo });
  },
  userError: "",
  setUserError: (error: ReturnType<typeof getErrorMessage>) => {
    set({ userError: error });
  },
}));

export default useOKTAUserStore;
