import playBlue from "@assets/actions/play-blue.svg";
import { twMerge } from "tailwind-merge";

export type MediaThumbnailProps = {
  src?: string;
  height?: number;
};

export default function MediaThumbnail({ src, height }: MediaThumbnailProps) {
  return (
    <div className={twMerge(`relative w-full`)}>
      <div className="absolute inset-0 bg-grey-930 z-10"></div>
      <img
        src={src}
        width={206}
        height={height}
        alt="placehold"
        className="object-cover w-full h-full z-20"
      />
      <div className="absolute inset-0 flex items-center justify-center z-20">
        <img className="h-[42px]" src={playBlue} alt="play-white-button" />
      </div>
    </div>
  );
}
