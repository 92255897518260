import { ClientSideSecrets } from "@app/featureFlag/featureFlags";
import {
  FB_APP_ID,
  FB_AUTH_DOMAIN,
  FB_DATABASE_URL,
  FB_MEASURMENT_ID,
  FB_MESSAGING_SENDER_ID,
  FB_PROJECT_ID,
  FB_STORAGE_BUCKET,
  REACT_APP_URL,
} from "@helper/getEnvVariables";
import { getErrorMessage, reportError } from "@helper/getErrorMessage";
import { removeTrailingSlash } from "@helper/removeTrailingLeadingSlash";
import { getFeatureFlag } from "@helper/utils";
import { SubscribeToTopicResponse } from "@src/api/endpoint-handlers/subscribeToTpic";
import fetchBHApi from "@src/app/helper/fetchBHApi";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, isSupported } from "firebase/messaging";

const initializeFirebaseApp = async () => {
  const apiKey = await fetchSecret(ClientSideSecrets.FB_API_KEY);
  const firebaseConfig = {
    apiKey: apiKey,
    authDomain: FB_AUTH_DOMAIN,
    databaseURL: FB_DATABASE_URL,
    projectId: FB_PROJECT_ID,
    storageBucket: FB_STORAGE_BUCKET,
    messagingSenderId: FB_MESSAGING_SENDER_ID,
    appId: FB_APP_ID,
    measurementId: FB_MEASURMENT_ID,
  };
  const UrlFirebaseConfig = new URLSearchParams({
    apiKey: apiKey,
    authDomain: FB_AUTH_DOMAIN,
    projectId: FB_PROJECT_ID,
    storageBucket: FB_STORAGE_BUCKET,
    messagingSenderId: FB_MESSAGING_SENDER_ID,
    appId: FB_APP_ID,
  });

  const paramsString = UrlFirebaseConfig.toString();
  const swUrl = `${removeTrailingSlash(REACT_APP_URL)}/firebase-messaging-sw.js?${paramsString}`;

  const firebaseApp = initializeApp(firebaseConfig);
  return { firebaseApp, swUrl };
};

const fetchSecret = async (key: ClientSideSecrets) => {
  const data = await getFeatureFlag(key);
  return data;
};

// Initialize messaging asynchronously and export a promise
export const messagingPromise = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      const { firebaseApp, swUrl } = await initializeFirebaseApp();
      const messaging = getMessaging(firebaseApp);
      return { messaging, swUrl };
    }
    console.log("Firebase is not supported in this browser");
    return null;
  } catch (err) {
    reportError({
      message: `
      An error occurred while initializing Firebase messaging:
      ${getErrorMessage(err)}`,
    });
    return null;
  }
})();

const waitForServiceWorkerActivation = (
  registration: ServiceWorkerRegistration,
): Promise<ServiceWorkerRegistration> => {
  return new Promise((resolve, reject) => {
    if (registration.active) {
      resolve(registration);
      return;
    }
    const serviceWorker = registration.installing || registration.waiting;
    if (serviceWorker !== null) {
      serviceWorker.addEventListener("statechange", (event) => {
        const target = event.target as ServiceWorker;
        if (target.state === "activated") {
          resolve(registration);
        }
      });
    } else {
      reject(new Error("No installing or waiting service worker found."));
    }
  });
};

export const getOrRegisterServiceWorker = async (swUrl: string) => {
  if (
    "serviceWorker" in navigator &&
    typeof navigator.serviceWorker !== "undefined"
  ) {
    try {
      const serviceWorker = await navigator.serviceWorker.getRegistration(
        "/firebase-cloud-messaging-push-scope",
      );
      if (serviceWorker)
        return await waitForServiceWorkerActivation(serviceWorker);
      const newServiceWorder = await navigator.serviceWorker.register(swUrl, {
        scope: "/firebase-cloud-messaging-push-scope",
      });
      return await waitForServiceWorkerActivation(newServiceWorder);
    } catch (error) {
      reportError({
        message: `Service worker registration failed: ${getErrorMessage(error)}`,
      });
    }
  } else {
    console.log("The browser doesn't support service worker.");
  }
};

export const getFirebaseToken = async () => {
  try {
    const messagingResolve = await messagingPromise;
    const vapidKey = await fetchSecret(ClientSideSecrets.FB_VAPID_KEY);
    if (messagingResolve) {
      const serviceWorkerRegistration = await getOrRegisterServiceWorker(
        messagingResolve.swUrl,
      );
      return await getToken(messagingResolve.messaging, {
        vapidKey: vapidKey,
        serviceWorkerRegistration,
      });
    } else {
      reportError({ message: "Firebase messaging is not initialized." });
      return null;
    }
  } catch (error) {
    reportError({
      message: `An error occurred while retrieving Firebase token: ${getErrorMessage(error)}`,
    });
    return null;
  }
};

// Called after user accepts the notification propmt.
export const subscribeTokenToTopic = async () => {
  try {
    const token = await getFirebaseToken();
    if (!token) {
      reportError({ message: "Failed to retrieve Firebase token." });
      return;
    }
    const response = await fetchBHApi<SubscribeToTopicResponse>(
      "subscribe-to-topic",
      "POST",
      {
        token,
      },
    );

    if (response.code != 200) {
      reportError({
        message: `Error subscribing to topic: ${response.data}`,
      });
      return;
    }

    console.log("Subscribed Firebase.");
    localStorage.setItem("isFirebasePushEnabled", "1");
  } catch (error) {
    reportError({
      message: `Error subscribing to topic: ${getErrorMessage(error)}`,
    });
  }
};
