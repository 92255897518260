import hash from "@app/helper/hash";
import useOKTAUserStore, { OKTAUserType } from "@app/store/useOKTAUserStore.ts";
import { getErrorMessage } from "@helper/getErrorMessage";
import Cookies from "js-cookie";
import { useCallback, useEffect } from "react";

/**
 * Note: This is the hook to get the user information from current ST mySPH session
 * @returns This current ST version of user information
 */
export default function useOKTAUserInfo() {
  const setUserInfo = useOKTAUserStore((state) => state.setUserInfo);
  const user = useOKTAUserStore((state) => state.userInfo);
  const setUserError = useOKTAUserStore((state) => state.setUserError);

  const handleFetchLoginDetails = useCallback(() => {
    // load user information
    fetchLoginDetails(
      (res) => {
        const newUserData: OKTAUserType = res.data;

        setUserInfo(newUserData);

        if (newUserData?.loginid) {
          Cookies.set("loginid", newUserData.loginid, { expires: 60 });
          async () => {
            await hash(newUserData.loginid as string).then((res) => {
              Cookies.set("mysph_lr", res, { expires: 60 });
            });
          };
        }

        windowDataLayer();

        if (!newUserData?.loginid) trySingleSignOnLogin();

        if (typeof window._data !== "undefined") {
          /* eslint-disable @typescript-eslint/no-unsafe-member-access */
          window._data.visitorcat = newUserData?.service_type_value;
          /* eslint-disable @typescript-eslint/no-unsafe-member-access */
          if (typeof newUserData?.mysphw !== "undefined") {
            window._data.at = newUserData.mysphw;
          }
          /* eslint-disable @typescript-eslint/no-unsafe-member-access */
          if (typeof newUserData?.svc !== "undefined") {
            window._data.svc = newUserData.svc;
          }
        }
        // Dispatch the event
        const event = new Event("ldapLoginDetailsReceived", {
          bubbles: true,
          cancelable: false,
        });
        document.dispatchEvent(event);
      },
      (error) => {
        const errorThrown = getErrorMessage(error);
        trySingleSignOnLogin();
        windowDataLayer();
        setUserError(errorThrown);
      },
    );
  }, [setUserInfo, setUserError]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      handleFetchLoginDetails();
      window.loadSubscriberContent = function () {
        handleFetchLoginDetails();
      };
    }
  }, [handleFetchLoginDetails]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      postMySPHSession(user);
    }
  }, [user]);
}

function fetchLoginDetails(
  successCallback: (res: Response & { data: OKTAUserType }) => void,
  errorCallback: (error: unknown) => void,
) {
  const url = `${import.meta.env.VITE_DRUPAL_API_ENDPOINT}/login_details.php?${new Date().valueOf()}`;

  fetch(url, { method: "POST" })
    .then(async (response) => {
      if (response.ok) {
        const data = (await response.json()) as OKTAUserType;
        successCallback({ ...response, data });
      } else {
        throw new Error(`HTTP status ${response.status}`);
      }
    })
    .catch((error: unknown) => {
      errorCallback(error);
    });
}

function windowDataLayer() {
  if (typeof window === "undefined") {
    return;
  }

  const userType = Cookies.get("mySPHUserType");
  // eslint-disable-next-line
  window.googletag = window.googletag || {};
  // eslint-disable-next-line
  window.googletag.cmd = window.googletag?.cmd || [];

  // Set Targeting based on userType
  // eslint-disable-next-line
  window.googletag?.cmd?.push(function () {
    let user_status = "N";
    if (userType != undefined) {
      if (userType == "y-sub") {
        user_status = "Y";
      } else if (userType == "y-reg") {
        user_status = "R";
      }
      googletag.pubads().setTargeting("subscriber", user_status);
    } else {
      //adding visitor information to st datalayer
      googletag.pubads().setTargeting("subscriber", user_status);
    }
  });

  const visitorcat = Cookies.get("visitorcat");
  const mysphw = Cookies.get("mysphw");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window.dataLayer as { push: (data: any) => void }).push({
    visitorcat: visitorcat,
    at: mysphw,
  });
}

//MYSPH singleSignon Integration
function trySingleSignOnLogin() {
  // eslint-disable-next-line no-console
  console.log("trying trySingleSignOnLogin");
  const f = document.createElement("iframe");
  f.src = "/mysph/login.php";
  f.style.width = "0px";
  f.style.height = "0px";
  f.style.border = "none";
  f.style.display = "none";
  document.body.append(f);
}

//Post MySPHSession
function postMySPHSession(data: OKTAUserType) {
  /* eslint-disable @typescript-eslint/no-unsafe-call */
  const inter = window.setInterval(function () {
    if (null != window._mySPHObj) {
      window.clearInterval(inter);
      window._mySPHObj.postSessionInformation(data?.mySPHSessionId as string);
    }
  }, 300);
}
