import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { useCallback } from "react";

export function useBHCustomEventAnalyticsCall() {
  const sendDataToGTM = useGTMDispatch();

  const analyticsCall = useCallback(
    (
      eventCategory: string,
      eventAction: string,
      eventLabel?: string,
      interaction?: boolean,
      pagination?: string,
      cdLanguage?: CD_LANGUAGE,
    ) => {
      sendDataToGTM({
        event: "bhCustomEvent",
        eventCategory: eventCategory,
        eventAction: eventAction,
        ...(eventLabel && { eventLabel: eventLabel }),
        ...(interaction && { nonInteraction: interaction }),
        ...(pagination && { pagination: pagination }),
        ...(cdLanguage && { cd_language: cdLanguage }),
      });
    },
    [sendDataToGTM],
  );

  return analyticsCall;
}

export enum CD_LANGUAGE {
  ENGLISH = "en",
  MALAY = "ms",
}
