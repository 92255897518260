import { getCookieByName } from "@helper/utils";
import { ReactElement } from "react";
import { Helmet } from "react-helmet-async";

export type GADataProps = {
  section: string;
  level2: string;
  chapter1?: string;
  chapter2?: string;
  chapter3?: string;
  at?: string;
  visitorcat?: 1 | 2 | undefined;
  svc?: string;
  title?: string;
  articleid?: string;
  author?: string;
  lotameid?: string;
  contentcat: "1" | "2" | "3";
  contenttype?:
    | "1"
    | "index/listing page"
    | "listing"
    | "video"
    | "webinar"
    | undefined;
  pubdate?: string;
  keyword?: string;
  pagination?: number;
  abVariant?: string;
  cue_articleid?: string;
  cd_version?: "bh2" | undefined;
  cd_language?: "ms" | "en";
  content_publication_date_utc?: string;
  content_update_date_utc?: string;
  infinite_scroll?: "0" | "1";
  // permutive_id?: string;
};

/**
 * GAData
 * Description: This component renders the _data for GA to consume.
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function GAData(props: GADataProps): ReactElement {
  const {
    section = "",
    level2 = "",
    chapter1 = "", // Section child category name.
    chapter2 = "",
    chapter3 = "",
    at = getCookieByName("mysphw") ?? "", // mysphw cookie
    visitorcat = getCookieByName("visitorcat") ?? 1,
    svc = getCookieByName("svc") ?? "",
    title = "", // can contain spaces
    articleid = "",
    author = "", // Comma separated author name
    lotameid = "",
    contentcat = 1, // Paid (2) or free (1)
    contenttype = "", // index/listing, article
    pubdate = "", // Post date of article otherwise ""
    keyword = "", // Comma separated keyword names
    pagination = 1,
    abVariant = "BH-DEFAULT",
    cue_articleid = "",
    cd_version = "bh2",
    cd_language = "ms",
    content_publication_date_utc = "",
    content_update_date_utc = "",
    infinite_scroll = "",
    // permutive_id = "", // window.localStorage.getItem("permutive-id") || "",
  } = props;

  return (
    <Helmet>
      <script id="ga_data_layer">
        {`
          var _data = {};
          _data = {
            "section"                      : "${section}",
            "level2"                       : "${level2}",
            "chapter1"                     : "${chapter1}",
            "chapter2"                     : "${chapter2}",
            "chapter3"                     : "${chapter3}",
            "at"                           : "${at}",
            "visitorcat"                   : ${visitorcat},
            "svc"                          : "${svc}",
            "title"                        : "${title}",
            "articleid"                    : "${articleid}",
            "author"                       : "${author}",
            "lotameid"                     : "${lotameid}",
            "contentcat"                   : "${contentcat}",
            "contenttype"                  : "${contenttype}",
            "pubdate"                      : "${pubdate}",
            "keyword"                      : "${keyword}",
            "pagination"                   : "${pagination}",
            "infinite_scroll"              : "${infinite_scroll}",
            "abVariant"                    : "${abVariant}",
            "cue_articleid"                : "${cue_articleid}",
            "cd_version"                   : "${cd_version}",
            "cd_language"                  : "${cd_language}",
            "content_publication_date_utc" : "${content_publication_date_utc}",
            "content_update_date_utc"      : "${content_update_date_utc}",
          };
          _data.permutive_id = window.localStorage.getItem("permutive-id") || "";

          window.addEventListener('load', function() {
          if (typeof window.getCookieByName === 'function') {
          
          if(window.getCookieByName("visitorcat") != undefined && window.getCookieByName("mysphw") != undefined) {
            var visitorcat = window.getCookieByName("visitorcat");
            _data.visitorcat = visitorcat;
            _data.at = window.getCookieByName("mysphw");
          }
          
          if(window.getCookieByName("svc") != undefined) {
            var svc = window.getCookieByName("svc");
            _data.svc = svc;
          }
         }
        });
     
        `}
      </script>
    </Helmet>
  );
}
