import {
  adSlotSizes,
  pubPageAdTargeting,
} from "@elements/Advertisement/AdsConstants";
import CompanionAdvertisement from "@elements/Advertisement/CompanionAdvertisement";
import { GoogleAdsSlotFactory } from "@elements/Advertisement/helpers";
import {
  AdTargetingType,
  AdvertisementTypeEnum,
  ArticleAdTargetKeys,
  PageAdTargetValue,
} from "@typings/Ads.d";
import { twMerge } from "tailwind-merge";

export function AISummaryAdsLbEnglish({
  uniqueSectionName,
  index,
  pageAdTargetType,
  gsChannels,
  articleSlotTarget,
  rootClassName,
  wrapperStyle,
  applySizeMapping = true,
}: {
  uniqueSectionName?: string;
  index: number;
  wrapperStyle?: string;
  pageAdTargetType: PageAdTargetValue;
  gsChannels?: string[];
  articleSlotTarget?: AdTargetingType<ArticleAdTargetKeys, string>[];
  overRideSlot?: boolean;
  rootClassName?: string;
  applySizeMapping?: boolean;
}) {
  const pageAdTarget = pubPageAdTargeting[pageAdTargetType] as AdTargetingType<
    string,
    PageAdTargetValue
  >;
  return (
    <div
      className={twMerge(
        "flex items-center justify-center w-full",
        wrapperStyle,
      )}
    >
      <CompanionAdvertisement
        companionAdUnitProps={{
          type: AdvertisementTypeEnum.LB2,
          slot: GoogleAdsSlotFactory.lb2(uniqueSectionName),
          id:
            index === 0
              ? "dfp-ad-lb2-summary-en"
              : `dfp-ad-imu1-summary-en-${index}`,
          rootClassName: rootClassName ? rootClassName : "m-0",
          pageNumber: index.toString(),
          pubAdTargeting: [pageAdTarget],
          gsChannels,
          articleSlotTarget,
          adSlotSize: adSlotSizes["lb2"],
          applySizeMapping,
        }}
      />
    </div>
  );
}

export function AISummaryAdsLbMalay({
  uniqueSectionName,
  index,
  pageAdTargetType,
  gsChannels,
  articleSlotTarget,
  rootClassName,
  wrapperStyle,
  applySizeMapping = true,
}: {
  uniqueSectionName?: string;
  index: number;
  wrapperStyle?: string;
  pageAdTargetType: PageAdTargetValue;
  gsChannels?: string[];
  articleSlotTarget?: AdTargetingType<ArticleAdTargetKeys, string>[];
  overRideSlot?: boolean;
  rootClassName?: string;
  applySizeMapping?: boolean;
}) {
  const PubPageAdTarget = pubPageAdTargeting[
    pageAdTargetType
  ] as AdTargetingType<string, PageAdTargetValue>;
  return (
    <div
      className={twMerge(
        "flex items-center justify-center w-full",
        wrapperStyle,
      )}
    >
      <CompanionAdvertisement
        companionAdUnitProps={{
          type: AdvertisementTypeEnum.LB2,
          slot: GoogleAdsSlotFactory.lb2(uniqueSectionName),
          id:
            index === 0
              ? "dfp-ad-lb2-summary-ms"
              : `dfp-ad-imu1-summary-ms-${index}`,
          rootClassName: rootClassName ? rootClassName : "m-0",
          pageNumber: index.toString(),
          pubAdTargeting: [PubPageAdTarget],
          gsChannels,
          articleSlotTarget,
          adSlotSize: adSlotSizes["lb2"],
          applySizeMapping,
        }}
      />
    </div>
  );
}
