/**
 * Company: SPHMedia
 * Description: [AI Summary Article Modal]
 */

import TranslateModalClose from "@assets/article/translateModalClose.svg";
import { FieldWrapper } from "@components/Translation/Elements/FieldWrapper";
import {
  AISummaryAdsLbEnglish,
  AISummaryAdsLbMalay,
} from "@elements/Advertisement/variant/AISummaryAds";
import {
  CD_LANGUAGE,
  useBHCustomEventAnalyticsCall,
} from "@hooks/useAnalyticsCall/useBHCustomEventAnalyticsCall";
import {
  AdTargetingType,
  ArticleAdTargetKeys,
  PageAdTargetingTypeEnum,
} from "@typings/Ads.d";
import Dompurify from "dompurify";
import React, { useState } from "react";
import { ReactElement } from "react";

export type AISummaryModalProps = {
  id: string;
  sectionName: string;
  english?: string[];
  malay?: string[];
  isOpen: boolean;
  onClose: () => void;
  gsChannels?: string[];
  articleSlotTarget?: AdTargetingType<ArticleAdTargetKeys, string>[];
  pageNumber: number;
};

/**
 * AISummaryModal
 * Description: [Opens AI summary content of article as a modal]
 * @param props - The props required by the component
 * @returns The rendered React element
 */
export default function AISummaryModal(
  props: AISummaryModalProps,
): ReactElement {
  const [showMalay, setShowMalay] = useState<boolean>(true);
  const {
    id,
    sectionName,
    english,
    malay,
    onClose,
    gsChannels,
    articleSlotTarget,
    pageNumber,
  } = props;
  const pageAdTargetEnglish = PageAdTargetingTypeEnum.SUMMARY_EN;
  const pageAdTargetMalay = PageAdTargetingTypeEnum.SUMMARY_MS;
  const bhCustomEvent = useBHCustomEventAnalyticsCall();

  const handleMalayClick = () => {
    setShowMalay(true);
    bhCustomEvent(
      "ai summary malay",
      "click",
      window.location.href,
      undefined,
      undefined,
      CD_LANGUAGE.MALAY,
    );
  };

  const handleEnglishClick = () => {
    setShowMalay(false);
    bhCustomEvent(
      "ai summary english",
      "click",
      window.location.href,
      undefined,
      undefined,
      CD_LANGUAGE.ENGLISH,
    );
  };

  return (
    <div
      id={`trans-${id}`}
      className="flex flex-col gap-[12px] items-center mb-md"
    >
      <div className="flex justify-between items-center w-full h-[48px] lg:h-[80px] lg:max-w-[860px] mx-auto bg-white-100 px-sm lg:px-0 sticky top-0">
        <div className="flex flex-col gap-xs">
          <span className="text-blue-400 text-sm font-primary font-semimedium">
            Summary by AI
          </span>
        </div>
        <div className="flex justify-between items-center gap-[16px]">
          <div>
            <button
              className={`w-[70px] lg:w-[120px] h-[32px] font-primary text-xs font-semimedium ${showMalay ? "bg-blue-400 text-white-500" : "bg-white-800 text-black-800 hover:bg-grey-1000 hover:text-black-950"}`}
              onClick={handleMalayClick}
              style={
                showMalay
                  ? { boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset" }
                  : undefined
              }
            >
              Malay
            </button>
            <button
              className={`w-[70px] lg:w-[120px] h-[32px] font-primary text-xs font-semimedium ${!showMalay ? "bg-blue-400 text-white-500" : "bg-white-800 text-black-800 hover:bg-grey-1000 hover:text-black-950"}`}
              onClick={handleEnglishClick}
              style={
                !showMalay
                  ? { boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset" }
                  : undefined
              }
            >
              English
            </button>
          </div>
          <button className="" onClick={onClose}>
            <img
              src={TranslateModalClose}
              alt="translate-close"
              width={23}
              height={23}
            />
          </button>
        </div>
      </div>
      <div className="flex flex-col w-full gap-xs">
        {showMalay && malay && (
          <>
            {malay.map((para, index) => (
              <React.Fragment key={index}>
                <FieldWrapper className="w-full">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: Dompurify.sanitize(para),
                    }}
                    className="text-sm font-primary font-semimedium text-black-800 leading-[24px] px-sm lg:px-0"
                  />
                </FieldWrapper>
              </React.Fragment>
            ))}
            <div className="w-full bg-white-200">
              <AISummaryAdsLbMalay
                uniqueSectionName={sectionName}
                index={pageNumber}
                pageAdTargetType={pageAdTargetMalay}
                gsChannels={gsChannels}
                articleSlotTarget={articleSlotTarget}
              />
            </div>
          </>
        )}
        {!showMalay && english && (
          <>
            {english.map((para, index) => (
              <React.Fragment key={index}>
                <FieldWrapper className="w-full">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: Dompurify.sanitize(para),
                    }}
                    className="text-sm font-primary font-semimedium text-black-800 leading-[24px] px-sm lg:px-0"
                  />
                </FieldWrapper>
              </React.Fragment>
            ))}
            <div className="w-full bg-white-200">
              <AISummaryAdsLbEnglish
                uniqueSectionName={sectionName}
                index={pageNumber}
                pageAdTargetType={pageAdTargetEnglish}
                gsChannels={gsChannels}
                articleSlotTarget={articleSlotTarget}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
