import { OKTAUserSeviceFlagEnum } from "@app/store/useOKTAUserStore";

import { getUserServiceFlag } from "./mySPH";

export default function hasServiceFlag(
  serviceFlagToCheck: OKTAUserSeviceFlagEnum,
) {
  const serviceFlags = getUserServiceFlag() || "";
  if (!serviceFlags) return false;
  const services = serviceFlags.split("|");
  return services.includes(serviceFlagToCheck);
}
