import CASBlock from "@components/CASBlock/CASBlock";
import PodcastGallery, {
  PodcastBHCustomEventCategory,
} from "@components/Gallery/PodcastGallery";
import VideoGallery from "@components/Gallery/VideoGallery";
import TranslationPopover from "@components/Translation/TranslationPopover";
import WordPuzzle from "@components/WordPuzzle/WordPuzzle";
import { stripeAdsBackground } from "@elements/Advertisement/Advertisement";
import { getTagsName } from "@elements/GAData/GADataHelper";
import { CAS_UGC_SECTION_BANNER_ID } from "@helper/getEnvVariables";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import { useGrapeshotData } from "@hooks/useGrapeshot";
import BHArticleBasePage, {
  AdsSettings,
  BHArticleBasePageProps,
} from "@pages/BHArticleBasePage";
import ErrorPage from "@pages/ErrorPage";
import NotFound from "@pages/NotFound";
import UGCArticleContentCard from "@pages/UGC/UGCArticlePage/UGCArticleContentCard";
import UGCArticleInfiniteScroll from "@pages/UGC/UGCArticlePage/UGCArticleInfiniteScroll";
import UGCArticleMetaData from "@pages/UGC/UGCArticlePage/UGCArticleMetaData";
import { PayloadResponseType } from "@pages/UGC/UGCArticlePage/UGCArticlePage.server";
import { MATA_MATA_SECTION } from "@pages/UGC/UGCLandingPage/UGCLandingPage";
import {
  CustomContext,
  TRouteWithRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { ResponseType } from "@sphtech/web2-core/ssr";
import { ProcessedArticleData } from "@transformer/useOSResponse";
import {
  AdTargetingType,
  ArticleAdTargetKeys,
  PageAdTargetingTypeEnum,
} from "@typings/Ads.d";
import { useState } from "react";

export function UgcArticlePage() {
  const routeContext: CustomContext<
    TRouteWithRedirect<PayloadResponseType, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  const [currentArticle, setCurrentArticle] = useState<ProcessedArticleData>();
  const adsSettings: AdsSettings = useGlobalAdSetting();
  const { gsChannels, isLoading } = useGrapeshotData(
    dataLoaderResponse.type === ResponseType.SUCCESS
      ? (dataLoaderResponse.payload.article.urlPath as string)
      : "",
  );

  if (dataLoaderResponse.type === ResponseType.CLIENT_ERROR) {
    return <NotFound />;
  } else if (dataLoaderResponse.type === ResponseType.SERVER_ERROR) {
    return <ErrorPage message={dataLoaderResponse.payload ?? ""} />;
  }

  if (dataLoaderResponse.type === ResponseType.SUCCESS) {
    const mainArticle = dataLoaderResponse.payload.article;
    const latestRegisteredArticle =
      dataLoaderResponse.payload.latestRegisteredArticle;

    const pageAdTargetValue = PageAdTargetingTypeEnum.ARTICLE;

    const handleNewArticle = (newArticle: ProcessedArticleData) => {
      setCurrentArticle(newArticle);
    };

    const { gaData, metaTags } = UGCArticleMetaData({
      article: currentArticle || mainArticle,
      isMainArticle: currentArticle ? false : true,
      gsChannels:
        currentArticle && currentArticle.gsChannels
          ? currentArticle.gsChannels
          : gsChannels,
    });

    const bhTags = getTagsName(mainArticle.tags || []);
    const bhArticleId = mainArticle.id;
    const articleSlotTarget: AdTargetingType<ArticleAdTargetKeys, string>[] = [
      { key: ArticleAdTargetKeys.ARTICLE_ID, value: bhArticleId },
      { key: ArticleAdTargetKeys.TAGS, value: bhTags.toLowerCase() },
    ];

    const basePageProps: BHArticleBasePageProps = {
      uniqueSectionName: MATA_MATA_SECTION,
      pageAdTargetValue,
      gaData,
      metaTags,
      suppliedAdsSettings: adsSettings,
      hasLb1Ads: false,
      gsChannels,
      articleSlotTarget,
    };

    if (isLoading) {
      return <div>Loading...</div>;
    }

    return (
      <>
        <BHArticleBasePage {...basePageProps}>
          <div
            className="flex flex-col xl:flex-row items-center justify-center max-w-full my-5 py-md min-h-[200px] lg:min-h-[220px]"
            style={stripeAdsBackground}
          >
            <CASBlock casId={CAS_UGC_SECTION_BANNER_ID} />
          </div>
          <div id={mainArticle.id} className="article-card">
            <UGCArticleContentCard
              mainArticle={mainArticle}
              latestRegisteredArticle={latestRegisteredArticle}
              pageAdTargetValue={pageAdTargetValue}
              pageNumber={0}
              isInfiniteScrollArticle={false}
              isOutbrainEnabled={adsSettings.isOutbrainEnabled || false}
              gsChannels={gsChannels}
              articleSlotTarget={articleSlotTarget}
            />
          </div>
          <VideoGallery />
          <PodcastGallery
            bhCustomEventProps={{
              eventCategory: PodcastBHCustomEventCategory.ARTICLE_PAGE,
              eventAction: "click",
            }}
          />
          <UGCArticleInfiniteScroll
            mainArticle={mainArticle}
            latestRegisteredArticle={latestRegisteredArticle}
            pageAdTargetValue={pageAdTargetValue}
            onNewArticle={handleNewArticle}
            isOutbrainEnabled={adsSettings.isOutbrainEnabled || false}
          />
          <TranslationPopover
            gsChannels={gsChannels}
            articleSlotTarget={articleSlotTarget}
          />
        </BHArticleBasePage>
        <WordPuzzle />
      </>
    );
  }
}
