import {
  NEWSLETTER_SIGN_UP_FAILED_ERROR_MSG,
  NEWSLETTER_SIGN_UP_INVALID_EMAIL,
} from "@components/Blocks/NewsLetterSignupForm/constants";
import fetchBHApi from "@helper/fetchBHApi";
import { getValidEmailRegex } from "@helper/utils";
import { NewsletterPostResponse } from "@typings/Newsletter";
import cx from "classnames";
import type { ReactElement } from "react";
import { FormEvent, useState } from "react";

import styles from "./NewsletterSignupForm.module.css";

type Props = Readonly<{
  testId?: string;
}>;
export default function SignupForm({
  testId = "newsletter-form",
}: Props): ReactElement {
  const [email, setEmail] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [successMsg, setSuccessMsg] = useState<string>("");

  async function handleOnSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();

    // validate email
    if (
      email &&
      getValidEmailRegex().test(email) // Valid email format
    ) {
      try {
        const queryParams = new URLSearchParams({
          email,
        }).toString();
        const res = await fetchBHApi(`newsletterSignup?${queryParams}`, "GET");
        const data = (await res) as NewsletterPostResponse;
        if (data.msg) {
          setErrorMsg(""); // unset error message
          setSuccessMsg(data.msg);
          return;
        }

        if (data.errorMsg) {
          setErrorMsg(data.errorMsg);
          return;
        }
      } catch (e: unknown) {
        setErrorMsg(NEWSLETTER_SIGN_UP_FAILED_ERROR_MSG);
        return;
      }
    } else {
      // Invalid email
      setErrorMsg(NEWSLETTER_SIGN_UP_INVALID_EMAIL);
      return;
    }
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value);
  }

  return (
    <form
      onSubmit={(e: FormEvent<HTMLFormElement>) => void handleOnSubmit(e)}
      data-testid={testId}
      className="mb-md"
    >
      <div
        className={cx(
          errorMsg || successMsg ? styles.alert : "",
          errorMsg ? styles.alertError : successMsg ? styles.alertSuccess : "",
        )}
      >
        {errorMsg || successMsg}
      </div>
      <div className={styles.formGroup}>
        <input
          onChange={handleInputChange}
          type="email"
          className={styles.formControl}
          placeholder="Enter your email address"
        />
      </div>
      <p className={cx(styles.privacy, "w-12/12 md:w-6/12 pl-0")}>
        Dengan menekan &apos;Langgan&apos; , anda mengesahkan bahawa anda telah,
        membaca, memahami dan bersetuju
        <a href="https://sph.com.sg/legal/website_tnc">
          {" "}
          Syarat dan Peraturan Ahli SPH{" "}
        </a>{" "}
        dan{" "}
        <a href="https://sph.com.sg/legal/sph_privacy"> Dasar Privasi SPH</a>
      </p>
      <button
        className={styles.btnNewsletter}
        aria-label="Sign Up for Newsletter"
      >
        Langgan
      </button>
    </form>
  );
}
