import androidWarningImage from "@assets/images/android-fb-inapp.jpg";
import iOSWarningImage from "@assets/images/ios-fb-inapp.png";
import checkInAppBrowser from "@helper/checkInAppBrowser";
import checkPlatformIsiOS from "@helper/checkPlatformIsiOS";
import { useEffect, useState } from "react";

export default function FoodMapInAppBrowserWarning() {
  const [isInAppBrowser, setIsInAppBrowser] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  useEffect(() => {
    const isFBInApp = checkInAppBrowser();
    const isIOSDevice = checkPlatformIsiOS();

    if (isFBInApp) {
      setIsInAppBrowser(true);
      setIsIOS(isIOSDevice);
    }
  }, []);

  if (!isInAppBrowser) {
    return null;
  }

  const imageUrl = isIOS ? iOSWarningImage : androidWarningImage;

  return (
    <div className="flex flex-col items-center absolute left-0 w-full h-full z-10 bg-black-600 text-sm pt-12 px-4 text-white-100 text-center">
      To enjoy all the features of the Halal Food Map, open this page with your
      favourite browser.
      <br />
      <img src={imageUrl} width={300} alt="Open in browser" />
    </div>
  );
}
