export function capitalize(str: string): string {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export function capitalizeFirstCharOnly(str: string): string {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function replaceSpecialChars(text: string) {
  // regex: remove non-alphanumeric chars except dash and replace with dash
  return text.toLowerCase().replace(/[^\w-]+|-+/g, "-");
}

export function removeNewlinesAndTabs(str: string) {
  return str.replace(/[\n\t]/g, "");
}

export function replaceDashAndWithSpace(text: string) {
  switch (text.toLowerCase()) {
    case "ekonomi-kerja":
      return "Ekonomi & Kerja";
    case "gaya-hidup":
      return "Gaya Hidup";
    case "bahasa-budaya":
      return "Bahasa Budaya";
    case "akses-percuma":
      return "Akses Percuma";
    case "premium-articles":
      return "Premium";
    case "registered":
      return "Pengguna Berdaftar";
    case "berita-utama":
      return "Berita Utama";
    case "kisah-zakat":
      return "Kisah Zakat";
    case "gen-g":
      return "Gen G";

    default:
      return text;
  }
}

export const truncateText = (text: string, charCount: number) => {
  if (text.length <= charCount) {
    return text;
  }

  const truncatedText = text.substring(0, charCount);
  const lastSpaceIndex = truncatedText.lastIndexOf(" ");

  return lastSpaceIndex === -1
    ? truncatedText
    : truncatedText.substring(0, lastSpaceIndex);
};

export function replaceSpacesWithUnderscores(str: string): string {
  // Replace all spaces with underscores
  return str.replace(/\s/g, "_");
}
