import { HeadingElements } from "@components/Elements/Typography/Heading";
import { LabelCardIconVariety } from "@components/Icon/LabelCardIcon";

export type OSResponse<T = unknown> = {
  message: string;
  _meta: {
    version: string;
    timestamp: string;
    requestId: string;
  };
  payload: {
    shards: unknown;
    hits: {
      hits: {
        _index: string;
        _id: string;
        _score: null | number;
        _source?: T;
        sort?: number[];
      }[];
      max_score: number | null;
      total: { value: number; relation: string };
    };
    took: number;
    timed_out: boolean;
  };
};

export type SectionResponse = {
  _meta: unknown;
  payload: {
    shards: unknown;
    hits: {
      hits: unknown;
      max_score: number | null;
      total: { value: number; relation: string };
    };
    aggregations: {
      unique_sections: {
        doc_count: number;
        u_s: {
          doc_count_error_upper_bound: number;
          sum_other_doc_count: number;
          buckets: {
            key: string[];
            key_as_string: string;
            doc_count: number;
          }[];
        };
      };
    };
    took: number;
    timed_out: boolean;
  };
};

export type StoryQueue = {
  data: {
    context: {
      storyQueueList: {
        content: { id: string };
      }[];
    };
  };
};
export type CueArticleSectionFields = {
  name: string;
  uniqueName?: string;
  directoryName?: string;
};

export type CueArticleSection = CueArticleSectionFields & {
  href?: string;
  parameters?: Array<unknown>;
  parent?: CueArticleSectionFields;
};

export type CueArticleId = {
  data?: {
    context?: {
      id: string;
    };
  };
};

export type PartialCueArticleData<T = ProcessedArticleContext> = {
  context?: T;
};

export type PartialCueArticle<T = ProcessedArticleContext> = {
  data?: PartialCueArticleData<T>;
  sqsMessage: unknown;
};

export type Tag = {
  name: string;
  urlPathHistory?: string[];
  urlPath: string;
};

export type embedcodes = {
  id: string;
  html: string;
}[];

export type ProcessedArticleContext = {
  typeName: string;
  created: string;
  updated: string;
  edited: string;
  authors?: {
    name: string;
    profiles?: AuthorProfile[];
  }[];
  paywall: {
    contentAccess: LabelCardIconVariety;
  };
  elements: ProcessedArticleContextElement[];
  storyline: string[];
  id: string;
  sections?: Array<CueArticleSection>;
  tags?: Tag[];
  title: string;
  media: {
    content: {
      fields: { caption: string } & RawImageField;
    };
    href: string;
    summary: { key: string; value: string };
  }[];
  attachments?: Attachment[];
  urlPath?: string;
  urlPathHistory?: string[];
  sort?: number[];
  others: { authoring?: string };
  seoFields?: {
    metaTitle?: string;
    metaDescription?: string;
    metaRobots?: number;
    canonicalUrl?: string;
  };
  ogFields?: {
    ogTitle?: string;
    ogDescription?: string;
  };
  embedcodes?: embedcodes;
  ogImages?: {
    content?: {
      fields?: {
        original?: RawImageField;
      };
    };
    href?: string;
  }[];
  aiService: AIService[];
};

export type CueUgcCampaignReferenceDetails = {
  id: string;
  type: string;
  href: string;
  title: string;
};

export type PartialCueArticleWithCampaign = PartialCueArticle & {
  data?: {
    context?: {
      campaignReference?: { content: CueUgcCampaignReferenceDetails }[];
    };
  };
};

export type RawImageField = {
  url: string;
  width?: number;
  height?: number;
};

export type AuthorProfile = {
  content?: {
    urlPath: string;
    fields?: {
      name: string;
      email?: string;
    };
    headshotImage?: {
      content: {
        fields: {
          original: RawImageField;
          "square_480-caas": RawImageField;
          "square_320-caas": RawImageField;
          "square_560-caas": RawImageField;
          "original-caas": RawImageField;
        };
      };
    }[];
  };
};

export type ProcessedAuthorContent = {
  name: string;
  email?: string;
  authorImage?: RawImageField;
  urlPath?: string;
  hasProfile: boolean;
};

export type ProcessedTagsContent = {
  name?: string;
  alias?: string;
};

export type ProcessedEmbedCodes = {
  id?: string;
  html?: string;
};

export type CropSelection = "portrait" | "landscape" | "square" | "wide";

export type RelationFields = {
  caption?: string;
  credit?: string;
  com_escenic_defaultmetadata?: unknown;
  original?: RawImageField;
  portrait?: RawImageField;
  landscape?: RawImageField;
  landscape480?: RawImageField;
  landscape1024?: RawImageField;
  square?: RawImageField;
  square_30_26?: RawImageField;
  wide?: RawImageField;
  freecrop?: RawImageField;
  "original-caas"?: RawImageField;
  "portrait-caas"?: RawImageField;
  "landscape-caas"?: RawImageField;
  "square-caas"?: RawImageField;
  "square_30_26-caas"?: RawImageField;
  "wide-caas"?: RawImageField;
  "freecrop-caas"?: RawImageField;
};

export enum GalleryCarouselTypes {
  IMAGE = "image",
  BRIGHTCOVE_VIDEO = "brightcove_video",
  YOUTUBE_VIDEO = "youtube_video",
  UNKNOWN = "unknown",
}

export type GalleryElementsBase = {
  id: string;
  type: GalleryCarouselTypes;
};

export type BrightcoveVideoGallery = GalleryElementsBase & {
  type: GalleryCarouselTypes.BRIGHTCOVE_VIDEO;
  videoId: string;
};

export type YoutubeVideoGallery = GalleryElementsBase & {
  type: GalleryCarouselTypes.YOUTUBE_VIDEO;
  youtubeId: string;
};

export type ImageGallery = {
  id: string;
  image: RawImageField | undefined;
  caption: string;
  credit: string;
};

export type ImageGalleryElement = GalleryElementsBase &
  ImageGallery & {
    type: GalleryCarouselTypes.IMAGE;
  };

export type UnknownGalleryElement = GalleryElementsBase & {
  type: GalleryCarouselTypes.UNKNOWN;
  id: string;
  name: string;
};

export type GalleryCarouselItems = (
  | ImageGalleryElement
  | BrightcoveVideoGallery
  | YoutubeVideoGallery
  | UnknownGalleryElement
)[];

export type defaultImageElement = {
  type: string;
  caption: string;
  credit: string;
}[];

export type RelatedArticles = {
  id: string;
  title: string;
  urlPath?: string;
}[];

export type ParagraphAnnotations = {
  index: number;
  length: number;
  name: string;
  value: string | ParagraphInlineLinkDetails;
};

export type ParagraphInlineLinkDetails = {
  url: string;
  target: "_self" | "_blank";
  rel: "nofollow" | "";
};

export type ProcessedStroylineElement = {
  id: string;
  position: number;
  type:
    | "paragraph"
    | "sub_head"
    | "image"
    | "embed"
    | "pull_quote"
    | "list_bulleted"
    | "list_numbered"
    | "youtube_video"
    | "brightcove_video";
};

export type ProcessedTextElement = {
  value: string;
  annotations: [] | ParagraphAnnotations[];
};

export type ProcessedFullParagraph = ProcessedStroylineElement &
  ProcessedTextElement & {
    type: "paragraph";
  };

export type ProcessedSubHead = ProcessedStroylineElement & {
  subhead: string;
  headingTag: HeadingElements;
  annotations: [] | ParagraphAnnotations[];
  type: "sub_head";
};

export type ProcessedInlineImage = ImageGallery &
  ProcessedStroylineElement & {
    type: "image";
  };

export type ContextElementField = {
  annotations: object[] | ParagraphAnnotations[];
  name: string;
  value: string;
  booleanValue?: boolean;
};

export type ProcessedArticleContextElement = {
  id: string;
  type: string | GalleryCarouselTypes;
  fields: ContextElementField[];
  children: string[];
  relation?: {
    id: string;
    type: string;
    fields: RelationFields;
    urlPath: string;
    href?: string;
    title?: string;
  };
};

export type ProcessedArticleBody = (
  | ProcessedFullParagraph
  | ProcessedSubHead
  | ProcessedInlineImage
  | ProcessedSocialEmbed
  | ProcessedPullQuote
  | ProcessedListItem
  | ProcessedYoutubeVideo
  | ProcessedBrightcoveVideo
)[];

export type ProcessedSocialEmbed = ProcessedStroylineElement & {
  embedUrl: string;
  embedHtml: string;
  type: "embed";
};

export type ProcessedPullQuote = ProcessedStroylineElement & {
  quoteLabel?: ProcessedTextElement;
  quote: ProcessedTextElement;
  quotee: ProcessedTextElement;
  quoteCredit?: ProcessedTextElement;
  type: "pull_quote";
};

export type ProcessedListItem = ProcessedStroylineElement & {
  listStyle: string;
  listItem: ProcessedTextElement[];
  type: "list_bulleted" | "list_numbered";
};

export type ProcessedYoutubeVideo = ProcessedStroylineElement & {
  youtubeId: string;
  youtubeTitle: string;
  type: "youtube_video";
};

export type ProcessedBrightcoveVideo = ProcessedStroylineElement & {
  videoId: string;
  type: "brightcove_video";
};

export type Attachment = {
  href: string;
  content: AttachmentContent;
};

export type AttachmentContent = {
  id: string;
  title: string;
  type: string;
  "attachmentLink-caas": string;
};

export type ProcessedWebinarContext = {
  typeName: string;
  id: string;
  title: string;
  storyline: string[];
  elements: ProcessedArticleContextElement[];
  sort?: number[];
};

export type TopArticleOSResponse<T = TopArticleContext> = {
  _meta: {
    version: string;
    timestamp: string;
    requestId: string;
  };
  payload: {
    data: {
      article_id: string;
      cue_id: string;
    }[];
    caas: {
      shards: unknown;
      hits: {
        hits: {
          _index: string;
          _id: string;
          _score: null | number;
          _source?: T;
        }[];
        max_score: number | null;
        total: { value: number; relation: string };
      };
      took: number;
      timed_out: boolean;
    };
  };
};

export type TopArticleContext = {
  data?: {
    context?: {
      title: string;
      urlPath: string;
    };
  };
};

export type AIService = {
  language: "english" | "malay";
  service: "summary" | "translation";
  name: "headline" | "standfirst" | "body" | "summary";
  value: string[];
};

export type ProcessedTranslatedContent = {
  headline: string;
  standFirst?: string;
  body: string[];
};

export type ProcessedSummaryContent = {
  english?: string[];
  malay?: string[];
};
