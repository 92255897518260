import "simplebar-react/dist/simplebar.min.css";
import "./ScrollBarStyle.css";

import ArticleCard from "@elements/Card/Article/ArticleCard";
import Heading from "@elements/Typography/Heading";
import { ProcessedArticleData } from "@transformer/useOSResponse";
import { MoreLinkProps } from "@typings/StoryPackage.d";
import SimpleBar from "simplebar-react";
import { twMerge } from "tailwind-merge";

import MoreLink from "./MoreLink";
import { TitleIconImgProps } from "./VerticalStoryPackage";

export type HorizontalStoryPackageStyles = {
  wrapperStyle?: string;
  titleStyle?: string;
  cardWrapperStyle?: string;
  cardInnerWrapperStyle?: string;
  cardWrapper?: string;
  cardHeaderWrapper?: string;
  cardContentWrapper?: string;
  cardDateTitleWrapper?: string;
  sectionNameWrapper?: string;
  sectionNameStyle?: string;
  moreLinkStyle?: string;
  imageWidth?: number;
  imageHeight?: number;
};

export type HorizontalStoryPackageProps = {
  renderItems: ProcessedArticleData[];
  title?: string;
  moreLink?: MoreLinkProps;
  styles?: HorizontalStoryPackageStyles;
  showSection?: boolean;
  showContentAccessLabel?: boolean;
  titleIcon?: TitleIconImgProps;
};

export default function HorizontalStoryPackage({
  title,
  renderItems,
  styles = {
    wrapperStyle: "",
    titleStyle: "",
    moreLinkStyle: "",
  },
  moreLink,
  showSection = true,
  showContentAccessLabel = true,
  titleIcon,
}: HorizontalStoryPackageProps) {
  const {
    wrapperStyle,
    titleStyle,
    cardWrapperStyle,
    cardInnerWrapperStyle,
    cardWrapper,
    cardHeaderWrapper,
    cardContentWrapper,
    cardDateTitleWrapper,
    sectionNameWrapper,
    sectionNameStyle,
    moreLinkStyle,
    imageWidth = 206,
    imageHeight = 155,
  } = styles;

  return (
    <div
      className={twMerge(
        "my-sm py-xs border border-red-600 border-x-0 border-b-0 border-t-2",
        wrapperStyle,
      )}
    >
      {title && (
        <div
          className={twMerge(
            "text-sm font-semibold text-grey-350 leading-[24px] mb-xs",
            titleStyle,
          )}
        >
          {titleIcon && (
            <img
              src={titleIcon.src}
              alt="title-icon"
              width={titleIcon.width}
              height={titleIcon.height}
            />
          )}
          <Heading Element="h4" className="font-primary" testId="heading">
            {title}
          </Heading>
        </div>
      )}
      <SimpleBar
        style={{ maxHeight: "100%", overflowX: "auto" }}
        className={twMerge("scrollBarStyles pb-5", cardWrapperStyle)}
      >
        <div
          className={twMerge(
            "flex gap-[10px] w-[2150px]",
            cardInnerWrapperStyle,
          )}
        >
          {renderItems.map((item, idx) => {
            return (
              <ArticleCard
                key={item.id}
                testId={`article-${idx}`}
                forNewDesign={true}
                displayWidth={imageWidth}
                displayHeight={imageHeight}
                overrideStyle={{
                  cardWrapper: cardWrapper
                    ? cardWrapper
                    : "flex flex-col items-center justify-center basis-[17%] break-words overflow-auto",
                  headerWrapper: cardHeaderWrapper
                    ? cardHeaderWrapper
                    : "block w-full",
                  contentWrapper: cardContentWrapper
                    ? cardContentWrapper
                    : "w-full",
                  dateStyle: "text-xxs text-black-700",
                  sectionNameWrapper: sectionNameWrapper
                    ? sectionNameWrapper
                    : "flex flex-col items-start gap-2",
                  sectionNameStyle: sectionNameStyle
                    ? sectionNameStyle
                    : "lg:text-[12px]",
                  dateTitleWrapper: cardDateTitleWrapper
                    ? cardDateTitleWrapper
                    : "flex flex-col-reverse gap-sm mt-xs",
                  titleStyle:
                    "lg:text-sm font-medium font-secondary text-black-800 leading-[19.2px]",
                  titleHoverStyle: "hover:text-blue-400",
                }}
                elements={{
                  hasSectionName: showSection,
                  hasNewIcon: showContentAccessLabel,
                }}
                {...item}
              />
            );
          })}
        </div>
      </SimpleBar>
      {moreLink && (
        <div
          className={twMerge(
            "flex items-center justify-end text-xxs leading-[18px] text-grey-350 font-semibold font-primary border-white-600 border-t-[1px] pt-xs",
            moreLinkStyle,
          )}
        >
          <MoreLink
            {...moreLink}
            className={twMerge("flex gap-1", moreLink.className)}
          />
        </div>
      )}
    </div>
  );
}
