import TranslateArticleCTA from "@assets/article/translateArticleCTA.svg";
import TranslateArticleCTAHover from "@assets/article/translateArticleCTAHover.svg";
import {
  CD_LANGUAGE,
  useBHCustomEventAnalyticsCall,
} from "@hooks/useAnalyticsCall/useBHCustomEventAnalyticsCall";
import { useBHVirtualPVAnalyticsCall } from "@hooks/useAnalyticsCall/useBHVirtualPVAnalyticsCall";
import { replaceSpacesWithUnderscores } from "@src/app/helper/stringTransform";
import { isClientSide } from "@src/app/helper/utils";
import { ProcessedArticleData } from "@transformer/useOSResponse";
import { AdTargetingType, ArticleAdTargetKeys } from "@typings/Ads.d";
import { GalleryCarouselTypes } from "@typings/Opensearch.d";
import { useEffect, useState } from "react";

import { getAt, getVisitorcat } from "./helper/GetValuesFromGAData";
import TranslatedArticleModal from "./TranslatedArticleModal";

export type TranslationCTAProps = {
  article: ProcessedArticleData;
  gsChannels?: string[];
  articleSlotTarget?: AdTargetingType<ArticleAdTargetKeys, string>[];
  pageNumber: number;
};

export default function TranslationCTA({
  article,
  gsChannels,
  articleSlotTarget,
  pageNumber,
}: TranslationCTAProps) {
  const { id, uniqueSectionName = "", imageGallery, translated } = article;
  const renderItems = imageGallery.filter(
    (item) => item.type === GalleryCarouselTypes.IMAGE,
  );
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isAnimating, setIsAnimating] = useState<boolean>(false);
  const bhCustomEvent = useBHCustomEventAnalyticsCall();
  const bhVirtualPV = useBHVirtualPVAnalyticsCall();
  const translatedQueryParam = "translated";

  useEffect(() => {
    if (!isClientSide) return;

    if (isModalOpen) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }
  }, [isModalOpen]);

  const handleCTAClick = () => {
    bhCustomEvent("translation", "click", window.location.href);
    const at = getAt();
    const visitorcat = getVisitorcat();
    const title = replaceSpacesWithUnderscores(translated?.headline || "");
    bhVirtualPV(title, CD_LANGUAGE.ENGLISH, at, visitorcat);

    const url = new URL(window.location.href);
    if (!url.searchParams.has(translatedQueryParam)) {
      // Add the translated query parameter
      url.searchParams.append(translatedQueryParam, "1");
    }

    window.history.pushState(
      { modal: "translation" },
      document.title,
      url.toString(),
    );

    setIsModalOpen(true);
    setTimeout(() => {
      setIsAnimating(true);
    }, 50);
  };

  useEffect(() => {
    if (!isClientSide) return;

    const handlePopState = () => {
      if (isModalOpen) {
        handleModalClose();
      }
    };

    // Add listener for popstate
    window.addEventListener("popstate", handlePopState);

    // Clean up the event listener
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [isModalOpen]);

  const handleModalClose = () => {
    setIsAnimating(false);
    setTimeout(() => {
      setIsModalOpen(false);

      // Remove the "?translated" query parameter
      const url = new URL(window.location.href);
      url.searchParams.delete(translatedQueryParam);
      window.history.replaceState({}, document.title, url.toString());
    }, 300);
  };

  if (!translated) return <></>;

  const { headline, standFirst, body } = translated;

  if (body.length == 1 && body[0] == "") return <></>;

  return (
    <div className="relative">
      {isModalOpen && (
        <div
          data-testid={`translated-modal-${id}`}
          className="fixed inset-0 overflow-y-auto w-full h-full bg-white-100 z-[52] pb-[16px]"
        >
          <div
            className={`transform transition-all duration-1000 ease-out
          ${
            isAnimating
              ? "translate-y-0 opacity-100"
              : "translate-y-full opacity-0"
          }
        `}
          >
            <TranslatedArticleModal
              id={id}
              title={headline}
              sectionName={uniqueSectionName}
              standFirst={standFirst}
              body={body}
              imageUrl={renderItems[0].image?.url}
              isOpen={isModalOpen}
              onClose={handleModalClose}
              gsChannels={gsChannels}
              articleSlotTarget={articleSlotTarget}
              pageNumber={pageNumber}
            />
          </div>
        </div>
      )}
      {!isModalOpen && (
        <div data-testid={`translate-cta-${id}`} className="w-[96px] h-[34px]">
          <button
            onClick={handleCTAClick}
            aria-label="Translate Article"
            style={{
              filter: "drop-shadow(0px 4px 1px rgba(0, 0, 0, 0.25))",
            }}
            className="group"
          >
            <img
              src={TranslateArticleCTA}
              alt="Translate Article Call to Action"
              width={96}
              height={34}
              className="group-hover:hidden"
            />
            <img
              src={TranslateArticleCTAHover}
              alt="Translate Article Call to Action hover"
              width={96}
              height={34}
              className="hidden group-hover:block"
            />
          </button>
        </div>
      )}
    </div>
  );
}
