import { type RelatedArticles } from "@app/types/Opensearch";
import nextArrow from "@assets/actions/next-arrow-related-articles.svg";
import Heading from "@elements/Typography/Heading";
import { BH_BASE_URL } from "@helper/getEnvVariables";
import {
  removeLeadingSlash,
  removeTrailingSlash,
} from "@helper/removeTrailingLeadingSlash";
import { isArrayWithElements } from "@helper/utils";

export default function RelatedArticle({
  relatedArticles,
}: {
  relatedArticles: RelatedArticles;
}) {
  return (
    <>
      <Heading
        Element="h6"
        className="italic font-primary text-md lg:text-2md my-4 pt-sm border-t border-grey-850"
        testId={`related-article-header`}
      >
        Laporan berkaitan
      </Heading>
      {isArrayWithElements(relatedArticles) &&
        relatedArticles.map(({ id, urlPath, title }, index) => {
          return (
            <div
              key={id}
              className="flex items-stretch justify-between w-full h-fit bg-white-200 border-b-8 border-white-300"
              data-testid={`related-article-wrapper-${index}`}
            >
              <a
                data-testid={`related-article-title-${index}`}
                href={urlPath}
                className="hover:underline font-secondary text-sm text-black-200  font-regular lg:font-semibold lg:text-md p-6 w-full"
              >
                {title}
              </a>
              <a
                className="w-[60px] flex items-center bg-white-400"
                href={urlPath}
              >
                <img
                  src={nextArrow}
                  alt="next-arrow"
                  className="hover:cursor-pointer m-auto"
                />
              </a>
            </div>
          );
        })}
    </>
  );
}

export function RelatedArticleMobile({
  relatedArticles,
}: {
  relatedArticles: RelatedArticles;
}) {
  return (
    <>
      <div className="paragraph paragraph--type--more-on-this-topic paragraph--view-mode--default">
        {"\n\n"}
        <div className="field field--name-field-related-articles field--type-entity-reference field--label-above">
          {"\n"}
          <div className="related-stories-title">Laporan berkaitan</div>
          {"\n"}
          <div className="related-stories-items">
            {"\n"}
            {isArrayWithElements(relatedArticles) &&
              relatedArticles.map(({ id, urlPath, title }, index) => {
                const fullUrl = `${removeTrailingSlash(BH_BASE_URL)}/${removeLeadingSlash(urlPath ?? "/")}`;
                return (
                  <div
                    key={id}
                    className="related-stories-item"
                    data-testid={`related-article-wrapper-${index}`}
                  >
                    <div className="related-stories-content">
                      <a
                        data-testid={`related-article-title-${index}`}
                        href={fullUrl}
                        hrefLang="en"
                      >
                        {title}
                      </a>
                      <a className="related-stories-arrow" href={fullUrl}>
                        &nbsp;
                      </a>
                    </div>
                    <div className="related-stories-border">{"&nbsp;"}</div>
                  </div>
                );
              })}
            {"\n"}
          </div>
          {"\n"}
        </div>
        {"\n\n"}
      </div>
      {"\n"}
    </>
  );
}
