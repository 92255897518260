import type { MoreArticlesRequestData } from "@app/types/MoreArticlesApi";
import { ProcessedAuthorContent } from "@app/types/Opensearch";
import AuthorCard from "@components/Elements/Card/Author/AuthorCard";
import useSetFirstRenderedArticleIds from "@components/Elements/LoadMore/SetFirstRenderedArticleIds";
import { CompanionAdsLb } from "@elements/Advertisement/variant/CompanionAdsLb";
import ArticleCard, {
  ArticleCardElementProps,
  ArticleCardStyleProps,
} from "@elements/Card/Article/ArticleCard";
import type { CreateLoadMoreHandlerProps } from "@elements/LoadMore/CreateLoadMoreHandler";
import { CreateLoadMoreHandler } from "@elements/LoadMore/CreateLoadMoreHandler";
import LoadMore from "@elements/LoadMore/LoadMore";
import fetchBHApi from "@helper/fetchBHApi";
import { displayAfter } from "@helper/utils";
import type { ProcessedArticleData } from "@transformer/useOSResponse";
import { PageAdTargetingTypeEnum } from "@typings/Ads.d";
import React, { useState } from "react";

const PAGE_SIZE = 10;
const INITIAL_ARTICLE_COUNT = 10;

type AuthorStoriesListProps = {
  articles: ProcessedArticleData[];
  author: ProcessedAuthorContent | undefined;
  authorAlias: string;
  sectionName: string;
};

export default function AuthorStoriesList({
  articles,
  author,
  authorAlias,
  sectionName,
}: AuthorStoriesListProps) {
  const [hasMoreStories, setHasMoreStories] = useState<boolean>(true);
  const [renderedArticleIds, setRenderedArticleIds] = useState(
    new Set<string>(),
  );
  const [loadMoreData, setLoadMoreData] = useState<ProcessedArticleData[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const showLoadMore: boolean = articles.length == INITIAL_ARTICLE_COUNT;

  /**
   * Sets a precaution for duplicate article ids when rendering additional articles
   */
  useSetFirstRenderedArticleIds(articles, setRenderedArticleIds);

  const handleLoadMore = async () => {
    const lastLoadedArticle =
      loadMoreData.length === 0 ? articles.at(-1) : loadMoreData.at(-1);
    const requestData: MoreArticlesRequestData = {
      filterArticlesBasedOn: authorAlias,
      sort: lastLoadedArticle?.sort,
    };

    const fetchMoreAuthorStoriesData = async (
      requestData: MoreArticlesRequestData,
    ): Promise<ProcessedArticleData[] | []> => {
      const queryParams = new URLSearchParams({
        authorUrlAlias: requestData.filterArticlesBasedOn as string,
        sort: Array.isArray(requestData.sort) ? requestData.sort.join(",") : "",
      }).toString();
      const response: ProcessedArticleData[] = await fetchBHApi(
        `more-author-stories?${queryParams}`,
        "GET",
      );

      return response;
    };
    const createLoadMoreHandlerProps: CreateLoadMoreHandlerProps = {
      requestData,
      fetchFunction: fetchMoreAuthorStoriesData,
      setHasMoreStories,
      setRenderedArticleIds,
      setLoadMoreData,
      setPageNumber,
      lastLoadedArticle,
      loadMoreData,
      renderedArticleIds,
      pageSize: PAGE_SIZE,
      pageNumber,
    };

    const response = await CreateLoadMoreHandler(createLoadMoreHandlerProps);
    return response;
  };

  const articleCardElements: ArticleCardElementProps = {
    hasSectionName: true,
    hasParagraph: true,
    hasDateSideBySide: true,
  };
  const articleCardOverrideStyle: ArticleCardStyleProps = {
    cardWrapper: "flex flex-row gap-4",
    contentWrapper: "gap-2",
    imageStyle: "w-[138px] md:w-[226px] xl:w-[284px]",
    hero: "mb-0",
    description:
      "font-primary text-xs leading-[150%] font-regular mb-sm hidden md:block",
    dateStyle: "md:py-0 text-xxs lg:text-sm",
    sectionNameItem: "lg:justify-start",
    titleStyle: "font-semimedium",
  };
  return (
    <>
      <div className="w-full">
        <div className="flex flex-col gap-8">
          {author && (
            <AuthorCard
              author={author}
              displayWidth={220}
              displayHeight={220}
            />
          )}
          {[...articles, ...loadMoreData].map((context, index) => (
            <React.Fragment key={index}>
              {displayAfter(index, PAGE_SIZE) && (
                <div data-testid={index} className="">
                  <CompanionAdsLb
                    uniqueSectionName={sectionName}
                    index={index / PAGE_SIZE}
                    pageAdTargetType={PageAdTargetingTypeEnum.LISTING}
                  />
                </div>
              )}
              <ArticleCard
                displayWidth={284}
                displayHeight={190}
                elements={articleCardElements}
                overrideStyle={articleCardOverrideStyle}
                {...context}
              />
            </React.Fragment>
          ))}
          {showLoadMore ? (
            <LoadMore
              rootClassName="my-4"
              onLoadMore={handleLoadMore}
              hasMore={hasMoreStories}
              loadText="Lagi Cerita"
            />
          ) : null}
        </div>
      </div>
    </>
  );
}
