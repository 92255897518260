export function getVisitorcat() {
  if (typeof window !== "undefined" && window._data) {
    const _data = window._data as { visitorcat: 1 | 2 | undefined };
    return _data.visitorcat;
  }
}

export function getAt() {
  if (typeof window !== "undefined" && window._data) {
    const _data = window._data as { at: string };
    return _data.at;
  }
}
