import ArticleCarousel from "@components/Carousel/ArticleCarousel";
import SideBlockAds from "@components/Container/SideBlockAds";
import ArticleParagraph from "@elements/ArticleParagraph/ArticleParagraph";
import AttachmentCard from "@elements/AttachmentCard/AttachmentCard";
import Outbrain from "@elements/OutbrainAds/Outbrain";
import ShareSocial, { SocialVariant } from "@elements/ShareSocial/ShareSocial";
import Date from "@elements/Typography/Date";
import Heading from "@elements/Typography/Heading";
import Link from "@elements/Typography/Link";
import { StandFirst } from "@elements/Typography/Paragraph";
import { RenderTags } from "@elements/Typography/Tags";
import { capitalizeFirstCharOnly } from "@helper/stringTransform";
import { isArrayWithElements } from "@helper/utils";
import { MATA_MATA_SECTION } from "@pages/UGC/UGCLandingPage/UGCLandingPage";
import { ProcessedArticleData } from "@transformer/useOSResponse";
import {
  AdTargetingType,
  ArticleAdTargetKeys,
  PageAdTargetValue,
} from "@typings/Ads.d";
import { GalleryCarouselTypes } from "@typings/Opensearch.d";

export type UgcArticleContentCardProps = {
  mainArticle: ProcessedArticleData;
  latestRegisteredArticle: ProcessedArticleData[];
  pageAdTargetValue: PageAdTargetValue;
  pageNumber: number;
  isInfiniteScrollArticle?: boolean;
  isOutbrainEnabled: boolean;
  gsChannels?: string[];
  articleSlotTarget?: AdTargetingType<ArticleAdTargetKeys, string>[];
  index?: number;
};

export default function UGCArticleContentCard({
  mainArticle,
  latestRegisteredArticle,
  pageAdTargetValue,
  pageNumber,
  isInfiniteScrollArticle = false,
  isOutbrainEnabled = false,
  gsChannels,
  articleSlotTarget,
  index,
}: UgcArticleContentCardProps) {
  const {
    title,
    tags,
    date,
    dateCreated,
    time,
    articleBody,
    standFirst,
    contentAccess,
    urlPath,
    imageGallery,
    relatedArticles,
    attachments,
    id,
    campaignReference,
  } = mainArticle;
  const socialIconList = ["facebook", "telegram", "plainWhatsapp"];
  const pageUrl = `${urlPath}`;
  const renderItems = imageGallery.filter(
    (item) => item.type !== GalleryCarouselTypes.UNKNOWN,
  );

  return (
    <div key={index} className="flex flex-col lg:flex-row w-full gap-6">
      <div className="flex flex-col lg:px-xs">
        <div className="flex items-center gap-3 w-full">
          <Link
            className="text-md lg:text-lg text-blue-300 hover:text-blue-700 hover:underline"
            destination={`mata-mata/${campaignReference?.href.split("/").at(-1)}`}
          >
            {capitalizeFirstCharOnly(campaignReference?.title as string)}
          </Link>
        </div>
        <Heading
          Element="h1"
          className="font-secondary leading-2xl text-black-200 text-xl lg:text-4xl font-bold mb-sm"
        >
          {title}
        </Heading>
        {standFirst && <StandFirst className="my-6">{standFirst}</StandFirst>}
        {/* Tags dont dont render If alias not available in payload*/}
        <RenderTags tagsWrapper="mb-6" tags={tags} />

        <div className="flex justify-between">
          <Date date={date} time={time} variant={"or"} className="my-2" />
          <div className="flex self-end gap-1 mb-6">
            {socialIconList.map((variant, index) => (
              <ShareSocial
                variant={variant as SocialVariant}
                pagePath={pageUrl}
                pageTitle={title}
                dateCreated={dateCreated}
                key={index}
              />
            ))}
          </div>
        </div>

        {isArrayWithElements(renderItems) && (
          <ArticleCarousel
            renderItems={renderItems}
            sectionName={MATA_MATA_SECTION}
          />
        )}

        <ArticleParagraph
          articleId={id}
          contentAccess={contentAccess}
          articleBody={articleBody}
          uniqueSectionName={MATA_MATA_SECTION}
          relatedArticles={relatedArticles}
          latestRegisteredArticle={latestRegisteredArticle}
          pageNumber={pageNumber}
          isInfiniteScrollArticle={isInfiniteScrollArticle}
          gsChannels={gsChannels}
          articleSlotTarget={articleSlotTarget}
        />

        {isArrayWithElements(attachments) && (
          <div className="my-md">
            {attachments.map((attachment, idx) => (
              <AttachmentCard key={idx} attachment={attachment} />
            ))}
          </div>
        )}

        <div className="flex self-end gap-1 mt-6">
          {socialIconList.map((variant, index) => (
            <ShareSocial
              variant={variant as SocialVariant}
              pagePath={pageUrl}
              pageTitle={title}
              dateCreated={dateCreated}
              key={index}
            />
          ))}
        </div>
        {isOutbrainEnabled && urlPath && <Outbrain pathName={urlPath} />}
      </div>

      <SideBlockAds
        key={index}
        uniqueSectionName={MATA_MATA_SECTION}
        className="w-full max-w-[400px] py-xs px-6 sticky top-[130px]"
        adsOnly={true}
        hasImu3={false}
        pageAdTargetingProps={pageAdTargetValue}
        forInfiniteScroll={isInfiniteScrollArticle}
        pageNumber={pageNumber}
        gsChannels={gsChannels}
        articleSlotTarget={articleSlotTarget}
        index={index}
      />
    </div>
  );
}
