import { SECTION_LIST } from "@header/headerMenuConstants";
import { useSubscribeLink } from "@helper/checkMobileAppLink";
import { type ReactElement } from "react";

import { HeaderMenuList } from "./HeaderMenuList";

export type SectionQuickLinksProps = Readonly<{
  testId?: string;
}>;

export function SectionQuickLinks({
  testId = "desktop-quick-links",
}: SectionQuickLinksProps): ReactElement {
  const subLink = useSubscribeLink();

  return (
    <div
      data-testid={`${testId}-quickLinks`}
      className="h-fit border-t-blue-400 border-t-[2px] border-b-grey-300 border-b-[3px] text-sm font-semimedium"
    >
      <div
        data-testid={`${testId}-quickLinksInner`}
        className="flex items-center justify-between lg:pr-xxl"
      >
        <div
          data-testid={`${testId}-topicsListWrapper`}
          className="h-full overflow-x-scroll lg:overflow-x-visible"
        >
          <HeaderMenuList testId={testId} items={SECTION_LIST} type="topics" />
        </div>
        <div
          data-testid={`${testId}-subscriptionLink`}
          className="mr-xs hidden"
        >
          {subLink && (
            <a
              href={subLink}
              className="block w-[92px] h-[26px] rounded-[12px] border-orange-100 border bg-orange-100 text-white-100 text-center text-xxs font-semibold leading-[22px] hover:text-white-100 visited:text-white-100"
            >
              Langgan
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
