import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { useCallback } from "react";

import { CD_LANGUAGE } from "./useBHCustomEventAnalyticsCall";

export function useBHVirtualPVAnalyticsCall() {
  const sendDataToGTM = useGTMDispatch();

  const analyticsCall = useCallback(
    (
      title: string,
      cd_language?: CD_LANGUAGE,
      at?: string,
      visitorcat?: 1 | 2 | undefined,
    ) => {
      sendDataToGTM({
        event: "virtual_pv",
        title: title,
        ...(cd_language && { cd_language: cd_language }),
        ...(at && { at: at }),
        ...(visitorcat && { visitorcat: visitorcat }),
      });
    },
    [sendDataToGTM],
  );

  return analyticsCall;
}
