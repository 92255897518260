export const SgCoords = {
  lat: {
    top: 1.4701324,
    bot: 1.2402414,
  },
  lang: {
    left: 103.6154013,
    right: 104.0329373,
  },
};
