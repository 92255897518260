import {
  SOCIAL_EMBED_HEIGHT,
  SOCIAL_EMBED_HEIGHT_MOBILE,
} from "@elements/ArticleParagraph/Embeds/constants";
import WrapperContainer from "@elements/ArticleParagraph/ParagraphElements/WrapperContainer";
import { useWindowSize } from "@hooks/useWindowSize/useWindowSize";
import { YouTubeEmbed, YouTubeEmbedProps } from "react-social-media-embed";

type YouTubeEmbedCustomProps = {
  url: YouTubeEmbedProps["url"];
  className?: string;
};
type YouTubeEmbedCustomMobileProps = {
  videoId: string;
  videoTitle: string;
  videoUrl: string;
  width?: number;
  height?: number;
};
export default function YouTubeEmbedCustom({
  url,
  className = "",
}: YouTubeEmbedCustomProps): React.ReactElement {
  const { isScreenSM } = useWindowSize();
  const height = isScreenSM ? SOCIAL_EMBED_HEIGHT : SOCIAL_EMBED_HEIGHT_MOBILE;

  return (
    <WrapperContainer className={className}>
      <YouTubeEmbed url={url} width={"100%"} height={height} />
    </WrapperContainer>
  );
}

export function YouTubeEmbedCustomMobile({
  videoId,
  videoTitle,
  videoUrl,
  width = 0,
  height = 0,
}: YouTubeEmbedCustomMobileProps) {
  const embedUrl = `https://www.youtube.com/embed/${videoId}?feature=oembed`;

  return (
    <div className="sph-embed-videos embed-youtube">
      <a href={videoUrl} className="oembed-title">
        {videoTitle}
      </a>
      <div className="embed-content">
        <iframe
          width={width}
          height={height}
          src={embedUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          title={videoTitle}
        ></iframe>
      </div>
    </div>
  );
}
