import { ReactNode } from "react";

export enum LinkRel {
  NOFOLLOW = "nofollow",
  NOOPENER = "noopener",
  NOREFERRER = "noreferrer",
}

export type LinkProps = {
  destination: string;
  children: ReactNode;
  className?: string;
  relativePath?: boolean;
  target?: "_self" | "_blank";
  rel?: LinkRel | undefined;
  type?: string;
  transformCase?: boolean;
  handleClick?: () => void;
  testId?: string;
  isMobile?: boolean;
};

export default function Link({
  destination,
  className,
  children,
  relativePath = true,
  target = "_self",
  rel = undefined,
  type,
  transformCase = false,
  handleClick,
  testId = "link",
  isMobile = false,
}: LinkProps) {
  const href = transformCase ? destination.toLowerCase() : destination;
  const defaultClass = isMobile ? undefined : `text-sm text-grey-400`;
  return (
    <a
      href={relativePath ? `/${href}` : href}
      className={className ?? defaultClass}
      target={target}
      rel={rel}
      type={type}
      onClick={handleClick}
      data-testid={testId}
    >
      {children}
    </a>
  );
}
